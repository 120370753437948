<!-- 收付 -->
<template>
  <Modal v-model="showCreateModal" width="1000" >
    <!-- <h3 class="p-t-10">新增记录</h3> -->
    <Form ref="adjustment"
     :model="formData"
        :rules="formDataValidate"
        label-position="top">
      <Row :gutter="8">
        <i-col span="8">
          <FormItem label="付款日期" prop="date">
            <DatePicker
              placeholder="付款日期"
              v-model="formData.date"
              type="date"
              style="width: 100%"
            ></DatePicker>
          </FormItem>
        </i-col>

        <i-col span="8">
          <FormItem label="含税金额" prop="amount">
            <InputNumber
              v-model="formData.amount"
              style="width: 100%; color: #fff; background: #1a1a1a"
              placeholder="请填写含税金额"
            ></InputNumber>
          </FormItem>
        </i-col>
        <i-col span="8">
          <FormItem label="不含税金额" prop="amountWithoutTax">
            <InputNumber
              v-model="formData.amountWithoutTax"
              style="width: 100%; color: #fff; background: #1a1a1a"
              placeholder="请填写不含税金额"
            ></InputNumber>
          </FormItem>
        </i-col>
         <i-col span="8">
          <FormItem label="税率（%）" prop="taxRatio">
            <InputNumber
              v-model="formData.taxRatio"
              style="width: 100%; color: #fff; background: #1a1a1a"
              placeholder="请填写税率"
            ></InputNumber>
          </FormItem>
        </i-col>
         <i-col span="8">
          <FormItem label="是否已开发票" >
              <Checkbox  v-model="formData.invoiced">已开发票</Checkbox>
          </FormItem>
        </i-col>
        <i-col span="24">
          <FormItem label="备注">
            <Input
              type="textarea" v-model="formData.mark"
              :autosize="{ minRows: 2, maxRows: 5 }"
              placeholder="备注信息"
            />
          </FormItem>
        </i-col>
      </Row>
    </Form>
    <div slot="footer">
      <Button type="text" @click="showCreateModal = false"
        >取消</Button
      >
      <Button type="primary" @click="confirmCreate">确认</Button>
    </div>
  </Modal>
</template>
<script>
// import { toMoney } from '@/utils/wnumb_own'
import { ParseDate } from '@/utils/dateFormat'
import { createorupdatebillrepayment, getbillrepayment } from '@/api/statement/billrepayment'

export default {
  data () {
    return {
      showCreateModal: false,
      mark: '',
      name: '',
      formData: {
        agentCompanyId: 0,
        amount: 0,
        amountWithoutTax: 0,
        date: '',
        id: -1,
        invoiced: false,
        publisherId: this.publisherId,
        taxRatio: 0,
        mark: ''

      },
      formDataValidate: {
        date: [
          {
            required: true,
            message: ' '
          }
        ],
        amount: [
          {
            required: true,
            type: 'number',
            message: ' ',
            trigger: 'blur'
          }
        ],
        amountWithoutTax: [
          {
            required: true,
            type: 'number',
            message: ' ',
            trigger: 'blur'
          }
        ],
        taxRatio: [
          {
            required: true,
            type: 'number',
            message: ' ',
            trigger: 'blur'
          }
        ]
      }

    }
  },
  methods: {
    showModal (performanceId, companyArray, defaultId) {
      this.companyArray = companyArray
      this.formData.id = performanceId
      if (performanceId > 0) {
        this.initPerformanceDetail()
      }
      this.list = []
      this.chkfeeitemIds = []
      this.formData.publisherId = this.publisherId
      this.formData.agentCompanyId = defaultId || companyArray[0].id
      this.showCreateModal = true
    },
    // 获取收款详情
    initPerformanceDetail () {
      const that = this

      getbillrepayment({ billrepaymentId: that.formData.id }).then(res => {
        if (!res.errcode) {
          that.formData.amount = res.amount
          that.formData.amountWithoutTax = res.amountWithoutTax
          that.formData.invoiced = res.invoiced
          that.formData.taxRatio = res.taxRatio
          that.formData.date = res.date
          that.formData.mark = res.mark
        }
      })
    },
    // 确认变更
    confirmCreate () {
      const that = this
      that.$refs.adjustment.validate((valid) => {
        if (valid) {
          if (that.formData.date) {
            that.formData.date = ParseDate(that.formData.date)
          }
          createorupdatebillrepayment(that.formData).then((res) => {
            if (res.errcode) {
              that.$Notice.error({ title: '操作失败', desc: res.errmsg })
            } else {
              that.$store.commit('set_settlement_update', new Date())
              that.$Notice.success({ desc: '操作成功' })
            }
            that.showCreateModal = false
          })
        }
      })
    }
  },
  computed: {
    publisherId () {
      return this.$store.getters.token.userInfo.publisherId
    }
  }
}
</script>
